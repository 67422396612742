<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="card pt-2 p-2">
              <div class="row">
                <div class="col-md-8" v-if="formEnvio.tipo_producto_id == 1">
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA UNICA PARA TRANSPORTAR PETROLEO CRUDO
                      <br />PETROMINERALES COLOMBIA CORP. SUCURSAL COLOMBIA
                    </strong>
                  </h3>
                </div>
                <div class="col-md-8" v-else>
                  <h3 style="font-size: 15px; text-align: center">
                    <strong>
                      GUIA PARA TRANSPORTAR PRODUCTOS DERIVADOS DEL PETROLEO
                      <br />FRONTERA ENERGY COLOMBIA CORP.
                    </strong>
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-4">
                  <img
                    src="/img/frontera-pegaso-ermes.png"
                    style="width: 280px"
                  />
                </div>
                <div class="col-md-8">
                  <div class="row ml-5">
                    <div class="col-md-6">
                      <label for="nGuia">N° GUIA</label>
                      <input
                        disabled="disabled"
                        v-model="form.numeroGuia"
                        style="color: #ff0000; font-weight: bold"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="producto">TIPO PRODUCTO</label>
                      <br />
                      <input
                        class="form-control form-control-sm"
                        id="tipo_prod"
                        v-model="formEnvio.tipo_producto"
                        :class="
                          $v.formEnvio.tipo_producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light pt-2 p-2">
                <div class="col-lg-14">
                  <div class="row" style="font-size: 12px">
                    <div class="col-sm-6">
                      <label for="">LUGAR DE EXPEDICIÓN</label>
                      <input
                        v-model="form.ciudadOrigen"
                        type="text"
                        style="font-size: 13px"
                        placeholder="Lugar"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-sm-6">
                      <label for="fecha_expedicion1"
                        >FECHA Y HORA DE EXPEDICIÓN</label
                      >
                      <div class="row ml-1">
                        <input
                          type="date"
                          v-model="formEnvio.fecha_expedicion1"
                          style="font-size: 13px"
                          class="form-control form-control-sm col-md-6"
                          disabled
                        />
                        <input
                          v-model="formEnvio.hora_expedicion"
                          type="text"
                          class="form-control form-control-sm col-md-6"
                          style="font-size: 13px"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="ml-1">
                        <label for="campo_productor"
                          >PLANTA O CAMPO PRODUCTOR</label
                        >
                        <input
                          class="form-control form-control-sm"
                          style="font-size: 13px"
                          id="prod_liquid"
                          v-model="formEnvio.producto_liquido"
                          :class="
                            $v.formEnvio.producto_liquido_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <label for="remision">FACTURA O REMISIÓN N°</label>
                      <input
                        type="text"
                        v-model="formEnvio.remision"
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        placeholder="Orden de Cargue"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-8">
                      <label for="fecha_expedicion">DESPACHADO A:</label>
                      <input
                        type="text"
                        v-model="formEnvio.despachado_a"
                        style="font-size: 13px"
                        placeholder="Despachado A"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.despachado_a.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="campo_productor">CÓDIGO</label>
                      <input
                        type="text"
                        v-model="formEnvio.codigo_destino"
                        style="font-size: 13px"
                        placeholder="Codigo Dane"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.codigo_destino.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-8">
                      <label for="direccion_destino">DIRECCIÓN:</label>
                      <input
                        type="text"
                        v-model="formEnvio.direccion_destino"
                        style="font-size: 13px"
                        placeholder="Dirección"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.direccion_destino.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="campo_productor">CIUDAD</label>
                      <input
                        type="text"
                        v-model="formEnvio.ciudad_destino"
                        style="font-size: 13px"
                        placeholder="Ciudad"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.ciudad_destino.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-8">
                      <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                      <input
                        type="text"
                        v-model="formEnvio.conductor"
                        style="font-size: 13px"
                        placeholder="Nombre Conductor"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="campo_productor">CÉDULA</label>
                      <input
                        type="text"
                        v-model="formEnvio.documento_conductor"
                        style="font-size: 13px"
                        placeholder="Cedula Conductor"
                        label="cedula"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="empresa_transportadora"
                        >EMPRESA TRANSPORTADORA</label
                      >
                      <input
                        v-model="formEnvio.trans"
                        placeholder="Transportadoras"
                        label="razon_social"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.transportadora_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="cabezote">PLACAS DEL CABEZOTE</label>
                      <input
                        type="text"
                        v-model="formEnvio.vehiculo"
                        style="font-size: 13px"
                        placeholder="Placa Vehículo"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="remolque">PLACAS DEL TANQUE</label>
                      <input
                        type="text"
                        v-model="formEnvio.remolque"
                        style="font-size: 13px"
                        placeholder="Placa Remolque"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="lugar_origen">LUGAR DE ORIGEN</label>
                      <input
                        :class="
                          $v.formEnvio.sitio_origen_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        v-model="formEnvio.sitioOrigen"
                        placeholder="Sitio Origen"
                        label="nombre"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="fecha_cargue">FECHA</label>
                      <input
                        v-model="formEnvio.fecha_cargue"
                        type="date"
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="hora_cargue">HORA DE SALIDA</label>
                      <input
                        v-model="formEnvio.hora_cargue"
                        type="text"
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        :class="
                          $v.formEnvio.hora_cargue.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="lugar_destino">LUGAR DE DESTINO</label>
                      <input
                        type="text"
                        v-model="formEnvio.sitio_destino"
                        :class="
                          $v.formEnvio.sitio_destino.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        style="font-size: 13px"
                        placeholder="Sitio Destino"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                      <input
                        v-model="formEnvio.horas_vigencia"
                        :class="
                          $v.formEnvio.horas_vigencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        type="text"
                        style="font-size: 13px"
                        placeholder="Horas Vigencia"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-2">
                      <label for="hora_adicional">HORA ADICIONAL</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="formEnvio.hora_adicional"
                        disabled
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="horaAdicional in listasForms.horasAdicionales"
                          :key="horaAdicional.descripcion"
                          :value="horaAdicional.descripcion"
                        >
                          {{ horaAdicional.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-12">
                      <label for="sellos">DESCRIPCIÓN DEL PRODUCTO</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="formEnvio.sellos"
                        label="numero"
                        :options="listasForms.sellos"
                        style="font-size: 13px; background-color: #fff"
                        @input="buscarSellos()"
                        placeholder="Sellos"
                        multiple
                        disabled
                      ></v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <label for="gov">GOV</label>
                      <div class="input-group">
                        <input
                          type="number"
                          v-model="formEnvio.gov"
                          style="font-size: 13px"
                          class="form-control form-control-sm"
                          step="any"
                          :class="
                            $v.formEnvio.gov.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          disabled
                          id="gov"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="gsv">GSV</label>
                      <div class="input">
                        <input
                          type="number"
                          v-model="formEnvio.gsv"
                          style="font-size: 13px"
                          class="form-control form-control-sm"
                          step="any"
                          :class="
                            $v.formEnvio.gsv.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label for="nsv">NSV</label>
                      <input
                        type="number"
                        v-model="formEnvio.nsv"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        step="any"
                        :class="
                          $v.formEnvio.nsv.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      v-model="formEnvio.observaciones"
                      cols="30"
                      rows="4"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light pt-2 p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-3">
                      <label for="temp">FACTOR DE TEMPERATURA</label>
                      <input
                        type="number"
                        v-model="formEnvio.factor_temperatura"
                        style="font-size: 13px"
                        min="50"
                        max="250"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.factor_temperatura.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="temp">ANÁLISIS DE LAB (TEMP°)</label>
                      <input
                        type="number"
                        v-model="formEnvio.analisis_laboratorio"
                        style="font-size: 13px"
                        min="50"
                        max="250"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.analisis_laboratorio.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-2">
                      <label for="api">A.P.I</label>
                      <input
                        type="number"
                        v-model="formEnvio.api"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.api.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-lg-2">
                      <label for="bsw">B.S.W</label>
                      <input
                        type="number"
                        v-model="formEnvio.bsw"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.bsw.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                    <div class="col-lg-2">
                      <label for="azufre">S%</label>
                      <input
                        type="number"
                        v-model="formEnvio.azufre"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.azufre.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light pt-2 p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-12">
                      <label for="novedades"
                        >NOVEDADES - PROCESO STAND BY</label
                      >
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="formEnvio.novedades"
                        label="descripcion"
                        :options="listasForms.novedades"
                        style="font-size: 13px; background-color: #fff"
                        placeholder="Novedades"
                        multiple
                        disabled
                      ></v-select>
                      <div class="error" v-if="formEnvio.novedades">
                        Seleccione las novedades
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-2">
                  <button class="btn bg-gray-dark col-md-11" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
                <div class="col-md-2">
                  <a
                    id="botonImp"
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="print()"
                    v-show="
                      accion == 'Show' && (estadoGuia == 6 || estadoGuia == 2)
                    "
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Guía
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import $ from "jquery";
export default {
  name: "DetGuiasTercerosForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        ciudad_destino: null,
        ciudadOrigen: null,
        numeroGuia: null,
      },
      formEnvio: {
        id: null,
        tipo_producto_id: null,
        fecha_expedicion: null,
        fecha_expedicion1: null,
        hora_expedicion: null,
        remision: null,
        tipo_vehiculo_id: null,
        transportadora_id: null,
        producto_liquido_id: null,
        codigo_destino: null,
        direccion_destino: null,
        sitio_origen_id: null,
        sitio_id: null,
        guia_id: null,
        horas_vigencia: null,
        hora_adicional: null,
        novedades: [],
        sellos: [],
        api: null,
        numeroGuia: null,
        user_id: null,
        tipo_operacion: null,
        estado: null,
      },
      estadoGuia: null,
      sitio_id: null,
      accion: null,
      actoGuia: null,
      listasForms: {
        novedades: [],
        sellos: [],
        horasAdicionales: [],
      },
      acto: "",
    };
  },
  validations: {
    formEnvio: {
      fecha_expedicion1: {
        required,
      },
      hora_expedicion: {
        required,
      },
      tipo_producto_id: {
        required,
      },
      codigo_destino: {
        required,
      },
      despachado_a: {
        required,
      },
      ciudad_destino: {
        required,
      },
      direccion_destino: {
        required,
      },
      sitio_origen_id: {
        required,
      },
      sitio_destino: {
        required,
      },
      horas_vigencia: {
        required,
      },
      producto_liquido_id: {
        required,
      },
      guia_id: {
        required,
      },
      novedades: {
        required,
      },
      sellos: {
        required,
      },
      fecha_cargue: {
        required,
      },
      hora_cargue: {
        required,        
      },
      analisis_laboratorio: {
        required,
      },
      factor_temperatura: {
        required,
      },
      transportadora_id: {
        required,
      },
      gov: {
        required,
      },
      gsv: {
        required,
      },
      nsv: {
        required,
      },
      api: {
        required,
      },
      bsw: {
        required,
      },
      azufre: {
        required,
      },
      observaciones: {
        required,
      },
    },
  },
  methods: {
    async init() {
      this.accion = this.$route.params.accion;
      this.sitio_id = this.$route.params.sitio_id;
      if (this.$route.params.accion == "Show") {
        this.formEnvio = this.$route.params.data_edit;
        this.acto = 2;
        this.estadoGuia = this.formEnvio.guia.estado;
        this.form.id = this.$route.params.data_edit.id;
        await this.getNovedades();
        this.formEnvio.sitioOrigen = this.formEnvio.sitio_origen.nombre;

        this.formEnvio.producto_liquido =
          this.formEnvio.producto_liquido.nombre;

        this.formEnvio.trans = this.formEnvio.transportadora.razon_social;

        if (this.$route.params.actoGuia !== 1) {
          this.form.numeroGuia =
            this.guia.numero + "-" + this.guia.digito_verificacion;
        } else {
          this.form.numeroGuia =
            this.formEnvio.guia.numero +
            "-" +
            this.formEnvio.guia.digito_verificacion;
        }

        this.formEnvio.horas_vigencia =
          this.formEnvio.horas_vigencia + " Horas";
        this.form.ciudadOrigen = this.formEnvio.ciudad_origen.nombre;
        this.formEnvio.tipo_producto = this.formEnvio.tipo_producto.nombre;
        let fecha = this.formEnvio.fecha_expedicion.slice(0, 10);
        let hora = this.formEnvio.fecha_expedicion.slice(11, 19);
        this.formEnvio.fecha_expedicion1 = fecha;
        this.formEnvio.hora_expedicion = hora;
        this.cargando = false;
      } else {
        this.cargando = false;
        this.id = this.$route.params.id;
      }
    },

    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    buscarSellos() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      axios
        .get(url, {
          params: {
            sitio_id: me.form.sitio_origen_id,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.sellos = respuesta;
          me.formEnvio.sello_id = me.listasForms.sellos.id;
          me.cargando = false;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    print() {
      $("#botonImp")
        .attr(
          "href",
          process.env.VUE_APP_API_URL +
            "/toPrint/guiaRegalias/" +
            this.formEnvio.id
        )
        .attr("target", "_blank");
      this.estadoGuia = 2;
    },

    back() {
      return this.$router.replace("/Com/GuiasTerceros");
    },
  },
  async mounted() {
    this.cargando = true;
    await this.init();
  },
};
</script>
